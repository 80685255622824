<template>
    <div class="HomePeoples">                           
        <div style="width:550px;font-size:36px;font-weight:100;text-align:center;margin-right:30px;">
            <div style="line-height:40px;">
                <span>健康风险人群</span>
            </div>                        
            <div style="margin-bottom:5px;font-size:14px;font-weight:500;text-align:left;margin-top:30px;color:black;">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{define1}}</span>
            </div>                                                                         
        </div> 

        <div class="people">
            <div v-for="item in peoples" :key="item.Key" class="peoplecard">
                <div class="producticon">
                    <el-image fit="scale-down" :src="require('@/images/48/'+`${item.Icon}`+'.png')" style="width:100%;height:100%;"/>
                </div>
                <div class="producttitle">
                    <span>{{item.Title}}</span>
                </div>
            </div>
        </div>                               
    </div>
</template>

<script>      
    export default {    
        //##############################################################################################################################
        components:
        {
            
        },    
        //############################################################################################################################## 
        data(){
            return{   
               peoples:[
                    {Key:1,Title:'低风险人群',Note:'个人体检报告',Icon:'dku.02900'},
                    {Key:3,Title:'一般风险人群',Note:'检验检查报告',Icon:'dku.02899'},
                    {Key:4,Title:'高风险人群',Note:'检验检查报告',Icon:'dku.02903'},
                    {Key:5,Title:'慢病患者人群',Note:'检验检查报告',Icon:'dku.02902'},
               ],  
               define1:'健康管理是指对个体进行全面的健康检査、风险评估、干预和跟踪的一种卫生服务。健康管理人群分类标准是为了更有针对性地对不同风险人群进行个性化管理，提高健康管理效果。根据风险程度，健康管理人群可分为低风险人群、一般风险人群、高风险人群以及慢病患者人群。',               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .HomePeoples{
        width:100%;
        display: flex;
    }
    .people{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;   
    }    
    .peoplecard{
        height:150px;
        width:150px;
        border:1px solid rgba(128, 128, 128, 0.3);
        margin:10px;
        border-radius: 150px;
        background-color: rgba(255,255,255,0.5);
        // box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);        
    }
    .producticon{
        // margin-left:auto;
        // margin-right:auto; 
        width:100%;
        height:48px;
        margin-top:35px;
    } 
    .producttitle{
        text-align: center;
        font-size:16px;
        line-height:30px;
        font-weight: 500;
    }       
</style>