<template>
    <div class="Home">
        <Scrollbar>
            <div class="content">
                <div class="header">
                    <Carousel class="carousel"
                        height="400px"
                        :interval="10000"
                        :datas="carouselitems"
                    >                        
                        <div slot="1" style="width:100%;">
                            <el-image fit="fill" :src="require('@/images/free/dku.00103.png')" style="width:100%;height:100%;"/>         
                        </div>  
                        <div slot="2" class="carousel-content">
                            <el-image fit="fill" :src="require('@/images/free/dku.00101.png')" style="width:100%;height:100%;"/>      
                        </div>   
                        <div slot="3" class="carousel-content">
                            <el-image fit="fill" :src="require('@/images/free/dku.00102.png')" style="width:100%;height:100%;"/>      
                        </div>                                                 
                    </Carousel>
                </div>
                <div>
                    <div v-for="block in blocks" :key="block.Key">
                        <div v-if="block.Key==2" :style="block.Style">
                            <HomeProduct/>
                        </div>
                        <div v-else-if="block.Key==3" :style="block.Style"> 
                            <HomeHealthDefine/>
                        </div>
                        <div v-else-if="block.Key==4" :style="block.Style">
                            <HomeDataCollect/>                             
                        </div>
                        <div v-else-if="block.Key==5" :style="block.Style">    
                            <HomePeoples/>                                                                      
                        </div>
                        <div v-else-if="block.Key==6" :style="block.Style">    
                            <HomeHealthApply/>                                                                      
                        </div>                        
                    </div>
                </div>
            </div>     
            <div>
                <SimpleConcat/>
            </div>
        </Scrollbar>
    </div>
</template>

<script>    
    import FrameContainer from "@/components/base/frame/FrameContainer"; 
    import Scrollbar from "@/components/base/Scrollbar";  
    import Carousel from "@/components/base/frame/Carousel";     
    import SimpleConcat from "@/views/template/SimpleConcat";  
    import HomeProduct from "@/views/home/HomeProduct";  
    import HomeHealthDefine from "@/views/home/HomeHealthDefine";  
    import HomeDataCollect from "@/views/home/HomeDataCollect";  
    import HomePeoples from "@/views/home/HomePeoples";
    import HomeHealthApply from "@/views/home/HomeHealthApply";
    export default {            
        //##############################################################################################################################
        components:
        {
            FrameContainer,Scrollbar,Carousel,SimpleConcat,HomeProduct,HomeHealthDefine,HomeDataCollect,HomePeoples,HomeHealthApply
        },    
        //############################################################################################################################## 
        data(){
            return{   
                blocks:[                    
                    {Key:2,Title:'产品和解决方案',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;"},
                    {Key:3,Title:'健康管理定义',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;display:flex;"},
                    {Key:4,Title:'健康数据采集',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;"},
                    {Key:5,Title:'健康风险人群',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;display:flex;"},
                    {Key:6,Title:'健康管理应用',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;display:flex;"}
                ],                        
               carouselitems:[
                    {Key:'1',Title:'单位'},
                    {Key:'2',Title:'职业'},                
                    {Key:'3',Title:'职业'}, 
               ]               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .Home{
        // height:calc(100vh - 62px);
        height:100%;
        width:100%;
        background-color: rgba(255, 255, 255, 0.1); 
        padding-top:5px;              
    }
    .content{
        margin-left:auto;
        margin-right:auto;  
        width:1100px;
        // background-color: rgba(128, 128, 128, 0.2); 
        text-align: left;     
    }
    .header{
        // width: 1100px;        
        // margin-left:auto;
        // margin-right:auto; 
        height: 400px;        
        text-align: center;                   
    }
    .carousel{
        width:100%;
        // background-color: rgba(0, 0, 255, 0.226);
    }
</style>