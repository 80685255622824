<template>
    <div class="FrameContainer">
        <div class="layout-hsm" v-if="layout=='hsm'">  
            <el-container>    
                <el-header :height="headerheight"><slot name="header"></slot></el-header>        
                <el-container> 
                    <el-aside :width="asidewidth"><slot name="aside"></slot></el-aside>                                                                                                      
                    <el-main><slot name="main"></slot></el-main> 
                </el-container>    
                <el-footer><slot name="footer"></slot></el-footer>                        
            </el-container>
        </div>     
        <div class="layout-hms" v-else-if="layout=='hms'">  
            <el-container>    
                <el-header :height="headerheight"><slot name="header"></slot></el-header>        
                <el-container> 
                    <el-main><slot name="main"></slot></el-main> 
                    <el-aside :width="asidewidth"><slot name="aside"></slot></el-aside>
                </el-container>                          
            </el-container>
        </div>            
        <div class="layout-shm" v-else-if="layout=='shm'">
            <el-container>
                <el-aside :width="asidewidth"><slot name="aside"></slot></el-aside>
                <el-container>
                    <el-header><slot name="header"></slot></el-header>
                    <el-main><slot name="main"></slot></el-main>
                </el-container>
            </el-container>
        </div>
        <div class="layout-sm" v-else-if="layout=='sm'">
            <el-container>
                <el-aside :width="asidewidth"><slot name="aside"></slot></el-aside>
                <el-main><slot name="main"></slot></el-main>
            </el-container>
        </div>
        <div class="layout-ms" v-else-if="layout=='ms'">
            <el-container>
                <el-main><slot name="main"></slot></el-main>
                <el-aside :width="asidewidth"><slot name="aside"></slot></el-aside>
            </el-container>
        </div>
        <div class="layout-hm" v-else-if="layout=='hm'">
            <el-container>
                <el-header :height="headerheight"><slot name="header"></slot></el-header>
                <el-main><slot name="main"></slot></el-main>
                <el-footer><slot name="footer"></slot></el-footer> 
            </el-container>
        </div>      
        <div class="layout-sm" v-else-if="layout=='m'">
            <el-container>                
                <el-main><slot name="main"></slot></el-main>
            </el-container>
        </div>                
    </div>    
</template>

<script>      
    export default {
        components:{},
        props:{
            layout:{
                type:String,//hsm-header-side-main;shm-side-header-main;sm-side-main;hm-header-main
                default:"hsm"
            },
            asidewidth:{
                type:String,
                default:'215px'
            },
            headerheight:{
                type:String,
                default:'28px'
            }            
        },                
    }
</script>

<style lang="scss" scoped>    
    .FrameContainer{
        width:100%;
        height:100%;
        padding:0rem!important; 
        margin: 0rem!important;          
        overflow: hidden;        
    }
    .el-container{
        width:100%;
        padding:0rem!important; 
        margin: 0rem!important;  
    }
    .el-header{    
        background-color:transparent !important;
        color: #333;
        padding: 0rem !important;
        margin: 0rem !important;
        width: 100%;
        
    }    
    .el-aside{        
        height:100% !important;               
        padding:0px;              
        background-color:transparent !important;
        overflow: hidden !important;             
    }       
    .el-main{        
        background-color:transparent !important;          
        overflow: hidden !important;  
        height:100% !important;         
        padding:0rem!important; 
        margin: 0rem!important;     
    }
    .el-footer{
        height:100% !important;         
        padding:0rem!important; 
        margin: 0rem!important;  
    }
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    }    
</style>