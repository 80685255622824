<template>
    <div class="HomeProduct">                           
        <div style="font-size:36px;font-weight:100;line-height:100px;text-align: center;">
            <span>产品和解决方案</span>
        </div>  
        <div class="product">
            <div v-for="item in products" :key="item.Key" class="productcard">
                <div class="producticon">
                    <el-image fit="scale-down" :src="require('@/images/48/'+`${item.Icon}`+'.png')" style="width:100%;height:100%;"/>
                </div>
                <div class="producttitle">
                    <span>{{item.Title}}</span>
                </div>
                <div class="productbutton">
                    <span>查看详情</span>
                </div>
            </div>
        </div>                               
    </div>
</template>

<script>      
    export default {    
        //##############################################################################################################################
        components:
        {
            
        },    
        //############################################################################################################################## 
        data(){
            return{   
                products:[
                    {Key:1,Title:'健康体检中心',Icon:'dku.02754'},                
                    {Key:3,Title:'个人健管中心',Icon:'dku.02090'},
                    {Key:4,Title:'单位健管中心',Icon:'dku.02613'},
                    {Key:5,Title:'产品管理中心',Icon:'dku.02680'},
                    {Key:2,Title:'数据交换平台',Icon:'dku.01992'},
                ]               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .product{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;       
    }
    .peoplecard{
        height:200px;
        width:200px;
        border:1px solid rgba(128, 128, 128, 0.3);
        margin:10px;
        border-radius: 200px;
        background-color: white;
        // box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);        
    }
    .productcard{
        height:200px;
        width:200px;
        border:1px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        border-radius: 4px;
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
        background-color: rgba(255,255,255,0.5);
    }
    .producticon{
        // margin-left:auto;
        // margin-right:auto; 
        width:100%;
        height:48px;
        margin-top:20px;
    }
    .producttitle{
        text-align: center;
        font-size:16px;
        line-height:80px;
        font-weight: 500;
    }
    .productbutton{
        font-size:9px;
        border:1px solid rgba(128, 128, 128, 0.8);
        border-radius:20px;
        width:70px;
        line-height:28px;
        text-align:center;
        margin-left:65px;
        cursor: pointer;
        color:grey;
        // margin-right:50px;
    }
</style>