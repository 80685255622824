<template>
    <div class="HomePeoples">                           
        <div style="width:100%;font-size:36px;font-weight:100;text-align:center;margin-right:30px;">
            <div style="line-height:40px;">
                <span>健康管理延伸服务</span>
            </div>                                                                                                
        </div> 

        <div class="people">
            <div v-for="item in peoples" :key="item.Key" class="peoplecard">
                <div class="producticon">
                    <el-image fit="scale-down" :src="require('@/images/48/'+`${item.Icon}`+'.png')" style="width:100%;height:100%;"/>
                </div>
                <div class="producttitle">
                    <span>{{item.Title}}</span>
                </div>
            </div>
        </div>                               
    </div>
</template>

<script>      
    export default {    
        //##############################################################################################################################
        components:
        {
            
        },    
        //############################################################################################################################## 
        data(){
            return{   
               peoples:[
                    {Key:1,Title:'健康商城',Icon:'dku.02189'},
                    {Key:2,Title:'快递公司',Icon:'dku.02466'},                                       
                    {Key:3,Title:'周边药店',Icon:'dku.02906'},
                    {Key:4,Title:'人寿保险',Icon:'dku.02938'},                     
                    {Key:5,Title:'医疗急救',Icon:'dku.02860'},
               ],                 
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .HomePeoples{
        width:100%;
    }
    .people{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;   
    }    
    .peoplecard{
        height:150px;
        width:200px;        
        margin:20px;    
    }
    .producticon{
        width:100%;
        height:48px;
        margin-top:35px;
    } 
    .producttitle{
        text-align: center;
        font-size:16px;
        line-height:30px;
        font-weight: 500;
    }       
</style>