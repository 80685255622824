<template>
    <div class="HomeDataCollect">                           
        <div style="font-size:36px;font-weight:100;line-height:100px;text-align: center;margin-top:0px;">
            <span>健康数据采集来源</span>
        </div>           
        <!-- <div style="font-size:14px;text-align: center;">
            <span>健康数据来源于医疗业务系统的检验检查报告、体检机构的个人体检报告、社区养老系统的检验检查报告以及个人穿戴设备的自测数据等</span>
        </div> -->
        <div class="product">
            <div v-for="item in sources" :key="item.Key" class="productcard">
                <div class="producticon">
                    <el-image fit="scale-down" :src="require('@/images/48/'+`${item.Icon}`+'.png')" style="width:100%;height:100%;"/>
                </div>
                <div class="producttitle">
                    <span>{{item.Title}}</span>
                </div>
                <div class="sourcenote">
                    <span>{{item.Note}}</span>
                </div>
            </div>
        </div>                               
    </div>
</template>

<script>      
    export default {    
        //##############################################################################################################################
        components:
        {
            
        },    
        //############################################################################################################################## 
        data(){
            return{   
               sources:[
                {Key:1,Title:'健康体检中心',Note:'个人体检报告',Icon:'dku.02754'},                
                {Key:3,Title:'医院门诊系统',Note:'检验检查报告',Icon:'dku.02860'},
                {Key:4,Title:'医院住院系统',Note:'检验检查报告',Icon:'dku.02018'},
                {Key:5,Title:'个人健管中心',Note:'穿戴设备数据',Icon:'dku.02090'},
                {Key:2,Title:'社区养老系统',Note:'检验检查报告',Icon:'dku.02647'},
               ],                
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .product{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;       
    }
    .productcard{
        height:200px;
        width:200px;
        border:1px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        border-radius: 4px;
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
        background-color: rgba(255,255,255,0.5);
    }
    .producticon{
        // margin-left:auto;
        // margin-right:auto; 
        width:100%;
        height:48px;
        margin-top:20px;
    }
    .producttitle{
        text-align: center;
        font-size:18px;
        line-height:80px;
        font-weight: 500;
    }
    .productbutton{
        font-size:9px;
        border:1px solid rgba(128, 128, 128, 0.8);
        border-radius:20px;
        width:70px;
        line-height:28px;
        text-align:center;
        margin-left:65px;
        cursor: pointer;
        // margin-right:50px;
    }
    .sourcenote{
        font-size:9px;
        text-align:center;
        color:grey;
    }    
</style>