<template>
    <div class="HomeHealthDefine">                           
        <div style="width:1060px;font-size:36px;font-weight:100;text-align:center;margin-right:30px;">
            <div style="line-height:50px;">
                <span>健康管理定义</span>
            </div>                        
            <div style="margin-bottom:5px;font-size:14px;font-weight:500;text-align:left;margin-top:30px;color:black;">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{define1}}</span>
            </div>  
            <div style="margin-bottom:5px;font-size:14px;font-weight:500;text-align:left;margin-top:0px;color:black;">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{define2}}</span>
            </div>                                                                        
        </div> 
        <div style="border-radius:5px;width:100%;">
            <el-image fit="scale-down" :src="require('@/images/free/dku.00120.png')" style="width:100%;height:100%;"/>
        </div>                                 
    </div>
</template>

<script>      
    export default {    
        //##############################################################################################################################
        components:
        {
            
        },    
        //############################################################################################################################## 
        data(){
            return{   
                define1:'健康管理以现代健康概念（生理、心理和社会适应能力）和新的医学模式（生理-心理-社会）以及中医治未病为指导，通过采用现代医学和现代管理学的理论、技术、方法和手段，对个体和群体整体健康状况及其影响健康的危险因素进行全面检测、评估、有效干预与连续跟踪服务的医学行为及过程',
                define2:'健康管理是以预防和控制疾病发生与发展，降低医疗费用，提高生命质量为目的，针对个体及群体进行健康教育，提高自我管理意识和水平，并对其生活方式相关的健康危险因素，通过健康信息采集、健康检测、健康评估、个性化健康管理方案、健康干预等手段持续加以改善的过程和方法'           
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .HomeHealthDefine1{
        display: flex;
    }
</style>