<template>
    <div class="SimpleConcat">
        <div class="website">
            <div style="width:1100px;margin-left:auto;margin-right:auto;display:flex;padding-top:0px;">
                <div style="min-width:100px;margin-top:5px;display:flex;">
                    <div style="height:96px;">
                        <el-image fit="scale-down" :src='require("@/images/256/dku.00002.png")' style="height:96px;"/>
                    </div>                          
                </div>
                <div style="width:100%;margin-top:34px;margin-right:60px;">                    
                    <div style="font-size:26px;line-height:30px;font-family:'华文行楷';">
                        <span >重庆德数云健康科技有限公司</span>
                    </div>
                    <div style="line-height:20px;font-size:12px;">
                        <span >ChongQing DigitHealthCloud Technology Co.,Ltd.</span>
                    </div>     
                </div>
                <div style="min-width:250px;text-align:left;margin-left:30px;margin-top:20px;">
                    <div style="height:20px;line-height:16px;">
                        <span>地址：</span>
                        <span style="margin-left:0px;">重庆市九龙坡区石新路 2 号</span>
                    </div>
                    <div style="height:20px;line-height:16px;">
                        <span>邮编：</span>
                        <span style="margin-left:0px;">400039</span>
                    </div>
                    <div style="height:20px;line-height:20px;display:flex;margin-top:0px;margin-left:0px;">
                        <span>域名：</span>
                        <span style="margin-left:0px;"><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">渝ICP备2024034001号-1</a></span>
                    </div>
                    <div style="height:20px;line-height:20px;display:flex;margin-top:0px;margin-left:0px;">
                        <span>网站：</span>
                        <span style="margin-left:0px;display:flex;">
                            <div>
                                <el-image fit="scale-down" :src='require("@/images/free/dku.00159.png")' style="height:18px;width:20px;"/>
                            </div>                            
                            <div>
                                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50010702505786" rel="noreferrer" target="_blank">渝公网安备50010702505786</a>                            
                            </div>                            
                        </span>
                    </div>                    
                </div>
                <div style="min-width:300px;text-align:left;margin-left:20px;margin-top:10px;">
                    <div style="height:85px;line-height:16px;display:flex;margin-top:2px;text-align:center;">
                        <div style="background:white;height:60px;">
                            <el-image fit="scale-down" :src='require("@/images/free/dku.00022.png")' style="height:80px;padding:0px;"/>
                            <div>
                                <span>联系微信号</span>
                            </div>
                        </div>
                        <div style="margin-left:30px;">
                            <el-tooltip class="item" effect="dark" placement="top-end">
                                <div slot="content" style="margin:5px;display:flex;cursor:pointer;">
                                    <el-image fit="scale-down" :src='require("@/images/48/dku.02146.png")' style="height:24px;margin-top:10px;"/>
                                    <div style="line-height:45px;margin-left:10px;font-size:14px;">
                                        18983912510
                                    </div>
                                    
                                </div>
                                <div>
                                    <el-image fit="scale-down" :src='require("@/images/48/dku.02267.png")' style="height:80px;border:0px solid grey;border-radius:4px;background:rgba(255,255,255,1);"/>
                                    <div>
                                        <span>联 系 我 们</span>
                                    </div>
                                </div>

                            </el-tooltip>
                        </div>                        
                        <div style="margin-left:30px;">
                            <el-image fit="scale-down" :src='require("@/images/free/dku.00021.png")' style="height:80px;"/>
                            <div>
                                <span>微信公众号</span>
                            </div>
                        </div>  
                                                               
                    </div>
                </div>
            </div>   
            <!-- <div style="width:1100px;margin-left:auto;margin-right:auto;display:flex;padding-top:15px;">
                        <span>备案：</span>
                        <span style="margin-left:0px;"><a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch">渝ICP备2024034001号-1</a></span>                        
                        <span style="margin-left:10px;display:flex;">
                            <div>
                                <el-image fit="scale-down" :src='require("@/images/free/dku.00159.png")' style="height:20px;width:30px;"/>
                            </div>
                            
                            <div>
                                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50010702505786" rel="noreferrer" target="_blank">渝公网安备50010702505786</a>                            
                            </div>                            
                        </span>                
            </div> -->
        </div>                       
    </div>
</template>

<script>    
    import FrameContainer from "@/components/base/frame/FrameContainer";     
    export default {    
        name:'login',
        //##############################################################################################################################
        components:
        {
            FrameContainer
        },    
        //############################################################################################################################## 
        data(){
            return{   
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .SimpleConcat{        
        height:100%;
        width:100% !important;  
        margin-left:0px !important;
        margin-right:0px !important;                    
    }
    .website{        
        height:120px;
        width:100%;  
        margin-left:0px !important;
        margin-right:0px !important;         
        background-color: rgba(24, 50, 136, 1);
        margin-top:5px;
        font-size:9px;
        text-align:center;
        line-height:50px;
        color:white;
    }
    a{
        color:white;
        text-decoration:none;
    }
    a:link{
        color:white;
    }
</style>