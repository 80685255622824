<template>
    <div class="Carousel">
        <el-carousel :height="height" :autoplay="autoplay" indicator-position="none" :interval="interval">
            <el-carousel-item v-for="item in datas" :key="item.Key" :name="item.Title">
                <slot :name="item.Key"></slot> 
                <slot name="content" :data="item"></slot>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>        
    export default {
        components:{},
        //##############################################################################################################################
        props:
        {
            datas:{type:Array,default:()=>([])},
            height:{type:String,default:''},
            autoplay:{type:Boolean,default:true},
            interval:{type:Number,default:6000},
        },        
        //##############################################################################################################################    
        data(){
            return{
            }
        },   
        //##############################################################################################################################
        methods:
        {
            
           
        },                                                   
    }
</script>


<style scoped>
    .Carousel
    {
        height: 100%;
        width: 100%; 
        overflow: hidden;   
        margin: 0px; 
        padding: 0px;         
        background-color: transparent; 
        /* border-radius: 5px; */
        /* background-color: red !important;  */
    }         
</style>


<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 9px;
    opacity: 0.75;
    /* line-height: 300px; */
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    /* background-color: #99a9bf; */
    background-color: green;
    background-color: transparent;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    /* background-color: #d3dce6; */
    background-color: red;
    background-color: transparent;
  }
</style>