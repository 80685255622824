<template>
    <el-scrollbar class="Scrollbar">  
        <slot></slot>
    </el-scrollbar>
</template>

<script>        
    export default {
        components:{},                   
    }
</script>

<style lang="scss" scoped>
    @import '@/style/handle.scss';
    .Scrollbar {
        margin: 0px;
        padding: 0px;   
        width: 100%;
        height:100%;    
    }          
</style>

<style lang="scss">
    @import '@/style/handle.scss';
    .Scrollbar .el-scrollbar__wrap{
        overflow-x: hidden;  
        // overflow-y: hidden;          
    }
    .Scrollbar .el-scrollbar__thumb {
        background-color: rgb(221, 221, 221,0.8);
        border-radius: 3px;                     
    }
</style>